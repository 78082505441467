import React from "react";
import { graphql, Link } from "gatsby";
import Seo from "../components/seo";
import get from "lodash/get";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Layout from "../components/layout";
import PageHero from "../components/page-hero";
import SidebarContactForm from "../components/sidebar-contact-form";
import PracticeAreaCard from "../components/practice-area-card";

class PracticeAreaCategoryTemplate extends React.Component {
  render() {
    const category = get(this.props, "data.contentfulPracticeAreaCategories");

    return (
      <Layout location={this.props.location}>
        <Seo
          title={`${category.title}`}
          description={`Our attorneys are ready to help with your ${category.title.toLowerCase()} case.`}
        />

        <section className="m-3">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/practice-areas/">Practice Areas</Link>
              </li>
              <li class="is-active">
                <Link to="#" aria-current="page">
                  {category.title}
                </Link>
              </li>
            </ul>
          </nav>

          <PageHero image={category.backgroundImage}>
            <div className="is-inline-block has-background-primary">
              <h1 className={"title has-text-white-bis pl-3 pr-4 pt-2 pb-2"}>
                {category.title}
              </h1>
            </div>
          </PageHero>
          <div className="columns mt-3">
            <div className="column">
              <div className="content">{renderRichText(category.body)}</div>
              {category.practiceAreas !== null && <hr />}
              {(category.practiceAreas || []).map((practiceArea) => {
                return <PracticeAreaCard practiceArea={practiceArea} />;
              })}
            </div>
            <div className="column is-one-third">
              <SidebarContactForm />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default PracticeAreaCategoryTemplate;

export const pageQuery = graphql`
  query PracticeAreaCategoriesBySlug($slug: String!) {
    contentfulPracticeAreaCategories(slug: { eq: $slug }) {
      slug
      title
      body {
        raw
      }
      shortBody {
        raw
      }
      image {
        title
        gatsbyImageData(layout: CONSTRAINED, width: 300)
      }
      practiceAreas {
        slug
        title
        body {
          raw
        }
        shortBody {
          raw
        }
        image {
          title
          gatsbyImageData(
            layout: FIXED
            width: 128
            height: 128
            resizingBehavior: THUMB
            cropFocus: CENTER
          )
        }
      }
      backgroundImage: image {
        title
        gatsbyImageData(
          layout: FULL_WIDTH
          resizingBehavior: SCALE
          placeholder: BLURRED
        )
      }
    }
  }
`;
