import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const PracticeAreaCard = ({ practiceArea }) => {
  const image = getImage(practiceArea.image);
  return (
    <article className="media">
      <figure className="media-left">
        <p className="image is-128x128">
          <GatsbyImage
            //className="is-rounded"
            placeholder="blurred"
            width={practiceArea.image.gatsbyImageData.width}
            height={practiceArea.image.gatsbyImageData.height}
            alt={practiceArea.image.title}
            image={image}
          />
        </p>
      </figure>

      <div className="media-content">
        <div className="content">
          <p>
            <strong>
              <Link
                to={`/practice-areas/${practiceArea.slug}/`}
                className="has-text-primary"
              >
                {practiceArea.title}
              </Link>
            </strong>
            <br />
            {renderRichText(practiceArea.shortBody)}
          </p>
        </div>
      </div>
    </article>
  );
};

export default PracticeAreaCard;
